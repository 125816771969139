import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import BookForm from '../components/elements/BookForm';
import Button from '../components/elements/Button';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const Correction = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'center-content',
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Paint Correction",
    paragraph: "Paint correction can feel like unearthing a diamond when you've properly restored the paint on a vehicle. This process can be timely but is very rewarding."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="200" />
          <div>

            <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
              <Image
                src={require("../assets/images/web_correction_thumb.jpg")}
              />
            </div>
                
            <div className="center-content">
              <p className="m-0 mt-32 mb-32">
                <span className="fw-700 text-color-primary"><span className="fw-600 text-color-high">RESTORED SHINE</span> &amp; VIBRANT COLORS</span>
              </p>
              <p>
                Paint defects can vary greatly, and can include simple light scratches here and there from damage in your own garage 
                to an excessive buildup of swirl marks after years of harsh and inappropriate washing techniques. 
                Fixing these discrepancies and restoring your original finish gloss requires us to follow a strict processes and planning. 
                All types of paint are different, with each affected by varying degrees. 
                This is why it&apos;s essential to choose an experienced paint correction specialist to inspect and treat your vehicle.
              </p>
            </div>
          </div>
          <div className="center-content reveal-from-left" data-reveal-delay="200">
            <p>
              <span className="fw-700 text-color-primary">PRICING</span>
            </p>
            <p>
              Paint Correction - <span className="text-color-high">$175/hr, 4 hour minimum</span>
            </p>
          </div>
          <Button tag="a" color="primary" wideMobile onClick={openModal}>
            Book Now
          </Button>
        </div>
        <Modal
          id="correction-modal"
          show={serviceModalActive}
          handleClose={closeModal}
        >
          <BookForm service="Paint Correction" />
        </Modal>
      </div>
    </section>
  );
}

Correction.propTypes = propTypes;
Correction.defaultProps = defaultProps;

export default Correction;