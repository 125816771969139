import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import Image from '../components/elements/Image';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const Gallery = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);


  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Gallery",
    paragraph: <span className="text-xxs">(To view a full-size image, right-click to open the image in new tab/window)</span>
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="100" />
          <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="200">
            <div className="gallery-grid">
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay={300}
                src={require("../assets/images/gallery1.jpg")}
                width={300}
                height={300}
              />
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay="450"
                src={require("../assets/images/gallery2.jpg")}
                width={300}
                height={300}
              />
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay="600"
                src={require("../assets/images/gallery3.jpg")}
                width={300}
                height={300}
              />
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay="750"
                src={require("../assets/images/gallery4.jpg")}
                width={300}
                height={300}
              />
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay="900"
                src={require("../assets/images/gallery5.jpg")}
                width={300}
                height={300}
              />
              <Image
                className="reveal-from-bottom"
                data-reveal-value="20px" data-reveal-delay="1050"
                src={require("../assets/images/gallery6.jpg")}
                width={300}
                height={300}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Gallery.propTypes = propTypes;
Gallery.defaultProps = defaultProps;

export default Gallery;