import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from './Button';
import Select from 'react-select';

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const BookForm = ({
  topDivider,
  bottomDivider,
  service
}) => {

  const [formData, setFormData] = useState({ 'service-required': service });
  const [postResult, setPostResult] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 'Ceramic Coating', label: 'Ceramic Coating' },
    { value: 'Paint Protection Film', label: 'Paint Protection Film' },
    { value: 'Paint Correction', label: 'Paint Correction' },
    { value: 'Full Service Wash', label: 'Full Service Wash' },
    { value: 'Polishing & paint enhancement', label: 'Polishing & paint enhancement' },
    { value: 'Rock Chip Repair', label: 'Rock Chip Repair' },
    { value: 'Window Tinting', label: 'Window Tinting' },
    { value: 'Full Service Detailing', label: 'Full Service Detailing' },
  ];

  const baseURL = "https://us-central1-theunderground-dc7e9.cloudfunctions.net";

  const formatResponse = (res) => {
    return <div style={{fontWeight: 700, paddingLeft: '15px'}}>
    <div>
      Thank you for booking, <span style={{color: '#A55'}}>{res.data.data}</span>!
    </div>
    <div>
      Our team will contact you as soon as possible to arrange all the details.
    </div>
  </div>;
  }
  
  async function postData() {
    if (
      formData["client-name"] &&
      formData["client-email"] &&
      formData["client-phone"] &&
      formData["vehicle-year"] &&
      formData["vehicle-make"] &&
      formData["vehicle-model"] &&
      formData["service-required"]
    ) {
      try {
        const res = await fetch(`${baseURL}/sendEmail`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": "token-value",
          },
          body: JSON.stringify(formData),
        });
        if (!res.ok) {
          const message = `An error has occured: ${res.status} - ${res.statusText}`;
          throw new Error(message);
        }
        const data = await res.json();
        const result = {
          status: res.status + "-" + res.statusText,
          headers: {
            "Content-Type": res.headers.get("Content-Type"),
            "Content-Length": res.headers.get("Content-Length"),
          },
          data: data,
        };
        setPostResult(formatResponse(result));
      } catch (err) {
        console.log(err.message)
        setPostResult("An error occured, please try again.");
      }
    } else {
      setPostResult("Please fill out all the required fields!")
    }
  }
  
  const handleInputChange = (e) => { 
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (vals) => {
    setFormData({
      ...formData,
      'service-options': vals
    });
  };

  const innerClasses = classNames(
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    
      <div className="container">
        <div
          className={innerClasses}
        >
          <Input onChange={handleInputChange} required name="client-name" id="client-name" type="text" label="Name" placeholder="Your full name *" />
          <Input onChange={handleInputChange} required name="client-email" id="client-email" type="tel" label="Phone" placeholder="Your email *" />
          <Input onChange={handleInputChange} required name="client-phone" id="client-phone" type="email" label="Email" placeholder="Your phone number *" />
          <Input onChange={handleInputChange} required name="vehicle-year" id="vehicle-year" type="text" label="Vehicle Year" placeholder="Your vehicle year *" />
          <Input onChange={handleInputChange} required name="vehicle-make" id="vehicle-make" type="text" label="Vehicle Make" placeholder="Your vehicle make *" />
          <Input onChange={handleInputChange} required name="vehicle-model" id="vehicle-model" type="text" label="Vehicle Model" placeholder="Your vehicle model *" />
          <Input onChange={handleInputChange} required name="client-message" id="client-message" type="textarea" label="Message" placeholder="Enter your message... " />
          <label className="form-label" htmlFor="service-options">Services required</label>
          <Select
            className="form-input form-multi-select"
            id="service-options"
            name="service-options"
            defaultValue={selectedOption}
            onChange={handleMultiSelectChange}
            options={options}
            isMulti
            placeholder="Please select required services"
          />
          <div className="center-content m-0 mt-32 mb-32">
            <Button
              tag="a"
              color="primary" 
              wideMobile 
              onClick={postData}
            >
              Submit
            </Button>
          </div>
        </div>
        { postResult && <div className="mt-16" role="alert">{postResult}</div> }
      </div>
  );
}

BookForm.propTypes = propTypes;
BookForm.defaultProps = defaultProps;

export default BookForm;