import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

import introVideo from '../../assets/videos/web_full_detail.mp4';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    document.getElementsByTagName('video')[0].onended = function () {
      this.load();
      this.play();
    };
    window.scrollTo({ top: 0 });
  }, []);

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-0 reveal-from-bottom" data-reveal-delay="200">
              The Underground
            </h1>
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400" style={{lineHeight: 0.5}}>
              <sub><em>Luxury Car <span className="fw-500 text-color-high">Care</span></em></sub>
            </p>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                <span className="fw-700 text-color-high">Trust</span> is in the <span className="fw-500 text-color-high">Details</span>
              </p>
              <h5 className="text-color-mid">
                We&apos;ve built our brand on top of the loyalty and trust shown by our customers who rely on us for thorough, 
                high quality cleaning and attention to detail. <span className="text-color-high">Meticulous, spotless and immaculate</span> describes our detailing services.
              </h5>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  {/* <Button tag="a" color="primary" wideMobile href="#0">
                    Book Now
                  </Button> */}
                  <Button tag="a" color="primary" wideMobile href="#services">
                    View Services
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
            <video width="896" height="504" autoPlay muted >
              <source src={introVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="reveal-from-bottom" data-reveal-delay="400">
            <p className="m-0 mt-32 mb-32">
              <span className="fw-500 text-color-high">Quality </span> is our <span className="fw-500 text-color-high">Primary Focus</span>
            </p>
            <h5 className="text-color-mid">
              We take pride in the quality of our work and attention to the little things.<br /> 
              Beyond the <span className="text-color-high">superior detailing </span> 
              services we provide, we make sure that <span className="text-color-high">our customers </span> 
              receive a <span className="text-color-high">quality</span> experience from <span className="text-color-high">every interaction</span>.
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;