import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import exteriorVideo from '../assets/videos/web_full_wash.mp4';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';
import BookForm from '../components/elements/BookForm';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const Exterior = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    document.getElementsByTagName('video')[0].onended = function () {
      this.load();
      this.play();
    };
    window.scrollTo({ top: 0 });
  }, []);

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'center-content',
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Exterior Detailing",
    paragraph: "Superior exterior detailing both you & your vehicle deserve."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="200" />
          <div>

            <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
              <video width="896" height="504" autoPlay muted >
                <source src={exteriorVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
                
            <div className="center-content">
              <p className="m-0 mt-32 mb-32">
                <span className="fw-700 text-color-primary"><span className="fw-600 text-color-high">BRILLIANT REFLECTION</span> &amp; SHINE</span>
              </p>
              <p>
                We pride ourselves in providing superior car detailing services in Calgary. 
                We offer a whole range of car care services and products designed to maintain, protect, recondition, and restore your vehicle.
                We understand that the appearance of your vehicle is a personal and professional reflection of yourself, 
                which is why we take every measure to ensure that image is polished. So whether you want your car to look its best for a business meeting, 
                a date, or every day cruising, count on the pros at The Underground to provide you with a thorough and deep clean for your vehicle that will be like no other.
              </p>
            </div>
          </div>
          <div className="center-content reveal-from-left" data-reveal-delay="200">
            <p>
              <span className="fw-700 text-color-primary">PRICING</span>
            </p>
            <p className="mb-0">
              Full Service Wash - <span className="text-color-high">$60</span>
            </p>
            <p className="mb-0">
              Polishing &amp; paint enhancement - <span className="text-color-high">$150</span>
            </p>
            <p>
              Rock Chip Repair - starting at <span className="text-color-high">$60 ($15 for second chip)</span>
            </p>
          </div>
          <Button tag="a" color="primary" wideMobile onClick={openModal}>
            Book Now
          </Button>
        </div>
        <Modal
          id="correction-modal"
          show={serviceModalActive}
          handleClose={closeModal}
        >
          <BookForm service="Exterior Detailing" />
        </Modal>
      </div>
    </section>
  );
}

Exterior.propTypes = propTypes;
Exterior.defaultProps = defaultProps;

export default Exterior;