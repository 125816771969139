import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import coatingVideo from '../assets/videos/web_ceramic_coat.mp4';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';
import BookForm from '../components/elements/BookForm';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const Coating = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    document.getElementsByTagName('video')[0].onended = function () {
      this.load();
      this.play();
    };
    window.scrollTo({ top: 0 });
  }, []);

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }   

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'center-content',
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Ceramic Coating",
    paragraph: "Your vehicles deserve nothing but the best. That's why owners and collectors of the finest vintage and exotic vehicles worldwide choose to ceramic coat their vehicle for the ultimate protection."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="200" />
          <div>
            
            <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
              <video width="896" height="504" autoPlay muted >
                <source src={coatingVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
              
            <div className="center-content reveal-from-right" data-reveal-delay="200">
              <p className="m-0 mt-32 mb-32">
                <span className="fw-700 text-color-primary"><span className="fw-600 text-color-high">PROTECTION</span> FROM HARMFUL ENVIRONMENTAL FACTORS</span>
              </p>
            <p>
              From salt &amp; sand, exhaust, pollutants, insect contaminants, moisture and UV rays, 
              your vehicle is vulnerable to numerous types of element-related corrosion. 
              In bonding to the substrate, ceramic coating provides a ceramic shield that protects your vehicles 
              from these elements.
            </p>
            </div>
            <div className="center-content reveal-from-left" data-reveal-delay="200">
              <p>
                <span className="fw-700 text-color-primary">LONG-LASTING BEAUTY WITH<span className="fw-600 text-color-high"> MINIMAL MAINTENANCE</span></span>
              </p>
              <p>
                The unique formula of the ceramic coating protections we use lock in long-lasting beauty 
                by keeping paint clean and retaining high gloss. What are the results? This greatly reduces 
                the time and expenses that are related to ongoing care and maintenance of your vehicle.
              </p>
            </div>
            <div className="center-content reveal-from-left" data-reveal-delay="200">
              <p>
                <span className="fw-700 text-color-primary">PRICING</span>
              </p>
              <p>
                Graphene &amp; Ceramic Coating, 3 years - starting at <span className="text-color-high">$699</span>
              </p>
            </div>
            
          </div>
          <Button tag="a" color="primary" wideMobile onClick={openModal}>
            Book Now
          </Button>
        </div>
        <Modal
          id="coating-modal"
          show={serviceModalActive}
          handleClose={closeModal}
        >
          <BookForm service="Ceramic Coating" />
        </Modal>
      </div>
    </section>
  );
}

Coating.propTypes = propTypes;
Coating.defaultProps = defaultProps;

export default Coating;