import React from 'react';
// import sections
import FeaturesTiles from '../components/sections/FeaturesTiles';

const PriceList = () => {

  return (
    <div className="section-inner">
      <FeaturesTiles priceList />
    </div>
  );
}

export default PriceList;