import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import ppfVideo from '../assets/videos/web_ppf.mp4';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';
import BookForm from '../components/elements/BookForm';
import Image from '../components/elements/Image';

import ppf1_image from '../assets/images/1_ppf_hood wrap.jpg';
import ppf2_image from '../assets/images/2_ppf_front_wrap.jpg';
import ppf3_image from '../assets/images/3_ppf_front_roof_wrap.jpg';
import ppf4_image from '../assets/images/4_ppf_full_front_wrap.jpg';
import ppf5_image from '../assets/images/5_ppf_full_front_rocker_wrap.jpg';
import ppf6_image from '../assets/images/6_ppf_full_wrap.jpg';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const PPF = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    document.getElementsByTagName('video')[0].onended = function () {
      this.load();
      this.play();
    };
    window.scrollTo({ top: 0 });
  }, []);

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'center-content',
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Paint Protection Film",
    paragraph: "PPF (Paint Protection Film) is the ultimate protection for your paint"
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="200" />
          <div>

            <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
              <video width="896" height="504" autoPlay muted >
                <source src={ppfVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
                
            <div className="center-content">
              <p className="m-0 mt-32 mb-32">
                <span className="fw-700 text-color-primary"><span className="fw-600 text-color-high">ULTIMATE PROTECTION</span> FROM CHIPS &amp; SCRATCHES</span>
              </p>
              <p>
                If you have a brand new car or you just had your paint fixed with some heavy paint correction, 
                then you&apos;ll probably want some protection for your paint before road grim, salt, dirt, and bugs get at it. 
                PPF (Paint Protection Film) is the ultimate protection for your paint, 
                not only will it protect your paint from the everyday elements, 
                but it will also protect it from light scuffs, scratches, and rock chips.
              </p>
            </div>
            <div className="center-content tiles-wrap mt-32">
              <div className="wrap-package">
                <span className="text-xxs">The Bodyguard Package</span>
                <Image alt="Hood Wrap Package" className="p-8" src={ppf1_image} width="175" />
              </div>
              <div className="wrap-package">
                <span className="text-xxs">VIP Package</span>
                <Image alt="Front Wrap Package" className="p-8" src={ppf2_image} width="175" />
              </div>
              <div className="wrap-package">
                <span className="text-xxs">CEO Package</span>
                <Image alt="Front &amp; Roof Package" className="p-8" src={ppf3_image} width="175" />
              </div>
              <div className="wrap-package">
                <span className="text-xxs">Secret Service Package</span>
                <Image alt="Full Front Package" className="p-8" src={ppf4_image} width="175" />
              </div>
              <div className="wrap-package">
                <span className="text-xxs">Vice Presidential Package</span>
                <Image alt="Full Front &amp; Rocker Package" className="p-8" src={ppf5_image} width="175" />
              </div>
              <div className="wrap-package">
                <span className="text-xxs">Presidential Package</span>
                <Image alt="Full Wrap Deluxe Package" className="p-8" src={ppf6_image} width="175" />
              </div>
            </div>
          </div>
          <div className="center-content reveal-from-left mt-32" data-reveal-delay="200">
            <p>
              <span className="fw-700 text-color-primary">PRICING</span>
            </p>
            <p>
              PPF (Paint Protection Film) - Starting at <span className="text-color-high">$699</span> (note: varies depending on vehicle &amp; package)
            </p>
          </div>
          <Button className="mt-32" tag="a" color="primary" wideMobile onClick={openModal}>
            Book Now
          </Button>
        </div>
        <Modal
          id="correction-modal"
          show={serviceModalActive}
          handleClose={closeModal}
        >
          <BookForm service="Paint Protection Film" />
        </Modal>
      </div>
    </section>
  );
}

PPF.propTypes = propTypes;
PPF.defaultProps = defaultProps;

export default PPF;