import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionShared } from '../utils/SectionProps';
import SectionHeader from '../components/sections/partials/SectionHeader';

import Image from '../components/elements/Image';
import Modal from '../components/elements/Modal';
import Button from '../components/elements/Button';
import BookForm from '../components/elements/BookForm';

const propTypes = {
  ...SectionShared.types
}

const defaultProps = {
  ...SectionShared.defaults
}

const Interior = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }

  const outerClasses = classNames(
    'section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'center-content',
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: "Interior Detailing",
    paragraph: "World-class interior detailing for your ultimate comfort & happiness."
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" data-reveal-delay="200" />
          <div>

            <div className="center-content hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
              <Image
                src={require("../assets/images/web_interior_thumb.jpg")}
              />
            </div>
                
            <div className="center-content">
              <p className="m-0 mt-32 mb-32">
                <span className="fw-700 text-color-primary"><span className="fw-600 text-color-high">IMPECABLE</span> ATTENTION TO DETAIL</span>
              </p>
              <p>
                We pride ourselves in providing superior car detailing services in Calgary. 
                We use the best professional tools and techniques to give your vehicle&apos;s interior an incredibly deep clean. We are able to clean all of those hard-to-reach places: under the seats, cracks and crevices, buttons, and vents.
                We understand that the appearance of your vehicle is a personal and professional 
                reflection of yourself, which is why we take every measure to ensure that image is polished. 
                So whether you want your car to look its best for a business meeting, a date, or every day cruising, 
                count on the pros at The Underground to provide you with a thorough and deep clean for your vehicle that will be like no other.
              </p>
            </div>
          </div>
          <div className="center-content reveal-from-left" data-reveal-delay="200">
            <p>
              <span className="fw-700 text-color-primary">PRICING</span>
            </p>
            <p className="mb-0">
              Full Service Detailing - starting at <span className="text-color-high">$250</span>
            </p>
            <p>
              Window Tinting - starting at <span className="text-color-high">$250</span>
            </p>
          </div>
          <Button tag="a" color="primary" wideMobile onClick={openModal}>
            Book Now
          </Button>
        </div>
        <Modal
          id="correction-modal"
          show={serviceModalActive}
          handleClose={closeModal}
        >
          <BookForm service="Interior Detailing" />
        </Modal>
      </div>
    </section>
  );
}

Interior.propTypes = propTypes;
Interior.defaultProps = defaultProps;

export default Interior;