import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Coating from './views/Coating';
import PPF from './views/PPF';
import Correction from './views/Correction';
import Exterior from './views/Exterior';
import Interior from './views/Interior';
import Gallery from './views/Gallery';
import PriceList from './views/Services';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal ref={childRef}>
      <Switch>
        <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
        <AppRoute exact path="/pricelist" component={PriceList} layout={LayoutDefault} />
        <AppRoute exact path="/coating" component={Coating} layout={LayoutDefault} />
        <AppRoute exact path="/ppf" component={PPF} layout={LayoutDefault} />
        <AppRoute exact path="/correction" component={Correction} layout={LayoutDefault} />
        <AppRoute exact path="/exterior" component={Exterior} layout={LayoutDefault} />
        <AppRoute exact path="/interior" component={Interior} layout={LayoutDefault} />
        <AppRoute exact path="/gallery" component={Gallery} layout={LayoutDefault} />
      </Switch>
    </ScrollReveal>
  );
}

export default App;