import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

import coating_icon from './../../assets/images/coating_icon.png';
import correction_icon from './../../assets/images/correction_icon.png';
import PPF_icon from './../../assets/images/PPF_icon.png';
import exterior_icon from './../../assets/images/exterior_icon.png';
import interior_icon from './../../assets/images/interior_icon.png';
import gallery_icon from './../../assets/images/gallery_icon.png';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  priceList,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title:  <p className="m-0 mb-32">
              <span className="fw-600 text-color-primary">{priceList ? 'Price List' : 'Our Services'}</span>
            </p>,
    paragraph:  <>{priceList ? '' : <h5 className="text-color-mid">
                  Our goal is to take the hassle away from getting your vehicle <span className="text-color-high">cleaned and detailed</span>. 
                  We <span className="text-color-high">simplify</span> pricing, inclusions and use an <span className="text-color-high">efficient process </span> 
                  to insure that you get your vehicle back in <span className="text-color-high">amazing condition</span>.
                </h5>}
                </>
  };        

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div id="services" className={innerClasses}>
          <SectionHeader data={sectionHeader} className={`center-content ${priceList && 'pb-0'}`} />
          <div className={tilesClasses}>

            <Link className="tiles-item-link" to="/coating">
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={coating_icon}
                        alt="Ceramic Coating"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-0">
                      Ceramic Coating
                    </h4>
                    {priceList ?
                      <p className="m-0 mb-8 text-xs">
                        starting at <span className="text-color-secondary">$699</span>
                      </p>
                      :
                      <p className="m-0 mt-8 text-sm">
                        Your vehicles deserve nothing but the best.
                        That&apos;s why owners and collectors of the finest vintage and exotic vehicles worldwide choose to ceramic coat their vehicle for the ultimate protection.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_coating_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>

            <Link className="tiles-item-link" to="/ppf">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={PPF_icon}
                        alt="Paint Protection Film"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-0">
                      Paint Protection Film
                    </h4>
                    {priceList ?
                      <p className="m-0 mb-8 text-xs">
                        starting at <span className="text-color-secondary">$699</span>
                      </p>
                      :
                      <p className="m-0 mt-8 text-sm">
                        Paint protection film is the most effective way to defend your vehicle from damage caused by rocks, sand, gravel, road debris or 
                        salt in the harsh Canadian winter. Make sure your investement is protected to your high standard!
                      </p>
                    }
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_ppf_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>

            <Link className="tiles-item-link" to="/correction">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={correction_icon}
                        alt="Paint Correction"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-0">
                      Paint Correction
                    </h4>
                    {priceList ?
                      <p className="m-0 mb-8 text-xs">
                        <span className="text-color-secondary">$175/hr, 4 hour minimum</span>
                      </p>
                      :
                      <p className="m-0 mt-8 text-sm">
                        Paint correction can feel like unearthing a diamond when you&apos;ve properly restored the paint on a vehicle. 
                        This is why it&apos;s essential to choose an experienced paint correction specialist to inspect and treat your vehicle.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_correction_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>

            <Link className="tiles-item-link" to="/exterior">
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={exterior_icon}
                        alt="Exterior Detailing"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-0">
                      Exterior Detailing
                    </h4>
                    { priceList ? <p className="m-0 mb-8 text-xs">
                      <p className="mb-0">
                        Full Service Wash - <span className="text-color-secondary">$60</span>
                      </p>
                      <p className="mb-0">
                        Polishing &amp; paint enhancement - <span className="text-color-secondary">$150</span>
                      </p>
                      <p className="mb-0">
                        Rock Chip Repair - starting at <span className="text-color-secondary">$60</span>
                      </p>
                    </p>
                    :
                      <p className="m-0 mt-8 text-sm">
                        We pride ourselves in providing superior car detailing services in Calgary. 
                        We offer a whole range of car care services and products designed to maintain, protect, recondition, and restore your vehicle.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_exterior_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>

            <Link className="tiles-item-link" to="/interior">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={interior_icon}
                        alt="Interior Detailing"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-0">
                      Interior Detailing
                    </h4>
                    { priceList ? 
                      <p className="m-0 mb-8 text-xs">
                        <p className="mb-0">
                          starting at <span className="text-color-secondary">$250</span>
                        </p>
                        <p className="mb-0">
                          Window Tinting - starting at <span className="text-color-secondary">$250</span>
                        </p>
                      </p>
                      :
                      <p className="m-0 mt-8 text-sm">
                        We understand that the appearance of your vehicle is a personal and professional reflection of yourself, 
                        which is why we take every measure to ensure that image is polished.
                      </p>
                    }
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_interior_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>

            {!priceList && <Link className="tiles-item-link" to="/gallery">
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        className={'rounded-profilepic'}
                        src={gallery_icon}
                        alt="Gallery"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Gallery
                    </h4>
                    <p className="m-0 text-sm">
                      We are proud of our work, the quality &amp; professionalism is reflected in everything we do. 
                      Browse through the gallery and see for yourself. Pictures speak a thousand words!
                    </p>
                  </div>
                </div>
              </div>
              <Image
                src={require("../../assets/images/web_gallery_thumb.jpg")}
                className="tiles-item-bgimg"
              />
            </Link>}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;