import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import Modal from '../elements/Modal';
import BookForm from '../elements/BookForm';
import Button from '../elements/Button';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  className: PropTypes.string
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const [serviceModalActive, setServiceModalActive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setServiceModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setServiceModalActive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <>
      <header
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
            <Logo />
            {!hideNav &&
              <>
                <button
                  ref={hamburger}
                  className="header-nav-toggle"
                  onClick={isActive ? closeMenu : openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={nav}
                  className={
                    classNames(
                      'header-nav',
                      isActive && 'is-active'
                    )}>
                  <div className="header-nav-inner">
                    <ul className={
                      classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                      )}>
                      <li>
                        <Link to="/pricelist" onClick={closeMenu}>Price List</Link>
                      </li>
                      <li>
                        <Link to="/coating" onClick={closeMenu}>Ceramic Coating</Link>
                      </li>
                      <li>
                        <Link to="/ppf" onClick={closeMenu}>Paint Protection Film</Link>
                      </li>
                      <li>
                        <Link to="/correction" onClick={closeMenu}>Paint Correction</Link>
                      </li>
                      <li>
                        <Link to="/exterior" onClick={closeMenu}>Exterior Detailing</Link>
                      </li>
                      <li>
                        <Link to="/interior" onClick={closeMenu}>Interior Detailing</Link>
                      </li>
                      <li>
                        <Link to="/gallery" onClick={closeMenu}>Gallery</Link>
                      </li>
                    </ul>
                    {!hideSignin &&
                      <ul
                        className="list-reset header-nav-right"
                      >
                        <li>
                          <Button
                            className="bounce"
                            size="sm"
                            tag="a"
                            color="primary"
                            wideMobile
                            onClick={(e) => {
                              closeMenu();
                              openModal(e);
                            }}
                          >
                            Contact Us
                          </Button>
                        </li>
                      </ul>}
                  </div>
                </nav>
              </>}
          </div>
        </div>
      </header>
      <Modal
        id="contact-us-modal"
        show={serviceModalActive}
        handleClose={closeModal}
      >
        <BookForm service="Contact Us" />
      </Modal>
    </>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
